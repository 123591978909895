<template>
  <div>
    <v-card>
      <v-snackbar
        v-if="alert.active"
        v-model="alert.active"
        :color="alert.type"
        :dismissible="alert.type == 'error'"
        class="rounded-b-0 mb-0"
      >
        <div class="fill-width text-center font-weight-medium">
          {{ alert.message }}
        </div>
      </v-snackbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-subheader class="px-0"> Básicos </v-subheader>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="store.name"
                label="Nombre"
                :rules="rules.required"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="store.slug"
                label="Slug"
                :hint="slug"
                :rules="rules.required"
                persistent-hint
                filled
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-textarea
                counter="150"
                rows="3"
                v-model="store.description"
                :rules="[
                  (v) =>
                    (v || '').length <= 150 ||
                    'La descripción debe tener 60 caracteres o menos.',
                ]"
                label="Breve descripción"
                filled
              ></v-textarea>
            </v-col>
          </v-row>

          <div v-if="store.informationAttributes">
            <v-row>
              <v-col cols="12">
                <v-subheader class="px-0"> Adicional </v-subheader>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="store.informationAttributes.businessField"
                  label="Rubro"
                  :items="categories"
                  item-text="name"
                  item-value="name"
                  placeholder="Ej: Farmacia, Alimentos y bebidas, Groceries"
                  filled
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="store.email"
                  label="Email comercial"
                  placeholder="ejemplo@gmail.com"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <div
                  style="gap: 12px"
                  class="fill-width d-flex flex-row align-center mb-6"
                >
                  <vue-google-autocomplete
                    id="map"
                    classname="form-control"
                    placeholder="
                       Dirección comercial
                    "
                    :value="
                      store.informationAttributes.addressAttributes.fullAddress
                    "
                    v-on:placechanged="setPlace"
                    class="px-3 fill-width v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"
                    style="
                      height: 56px;
                      background-color: rgba(0, 0, 0, 0.12);
                      border-bottom: 1px solid darkgrey;
                    "
                  >
                  </vue-google-autocomplete>
                  <v-btn icon color="primary" @click="modal = true">
                    <v-icon>mdi-map-marker</v-icon>
                  </v-btn>
                </div>

                <v-dialog v-if="marker" v-model="modal" width="800px">
                  <v-card>
                    <v-card-title>
                      Mapa
                      <v-spacer></v-spacer>
                      <v-btn icon color="primary" @click="modal = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <GmapMap
                        :center="{
                          lat: store.informationAttributes.latitude,
                          lng: store.informationAttributes.longitude,
                        }"
                        :zoom="16"
                        :options="{
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: true,
                        }"
                        style="width: 100%; height: 400px"
                      >
                        <GmapMarker
                          :position="{
                            lat: store.informationAttributes.latitude,
                            lng: store.informationAttributes.longitude,
                          }"
                          @click="center = marker"
                        />
                      </GmapMap>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </div>

          <div v-if="store.addressAttributes">
            <v-row>
              <v-col cols="12">
                <v-subheader class="px-0"> Información de retiro </v-subheader>
              </v-col>

              <v-col class="mb-3 pt-0" cols="12" md="6">
                <vue-google-autocomplete
                  id="pickupMap"
                  classname="form-control"
                  placeholder="Dirección de retiro"
                  :value="store.addressAttributes.fullAddress"
                  v-on:placechanged="setAddressPlace"
                  class="px-3 fill-width v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"
                  style="
                    height: 56px;
                    background-color: rgba(0, 0, 0, 0.12);
                    border-bottom: 1px solid darkgrey;
                  "
                >
                </vue-google-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="store.addressAttributes.unit"
                  label="Dpto. / Unidad"
                  placeholder="Piso 6 dpto. 1"
                  hint="Piso 6 Departamento 1"
                  persistent-hint
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="store.addressAttributes.phone"
                  label="Teléfono de contacto"
                  prefix="+56 9 "
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div v-if="store.preferencesAttributes">
            <v-row>
              <v-col cols="12">
                <v-subheader class="px-0"> SEO </v-subheader>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="store.preferencesAttributes.title"
                  label="Título"
                  hint="Se verá en la barra superior del navegador."
                  persistent-hint
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-combobox
                  dense
                  v-model="store.preferencesAttributes.metaKeywords"
                  label="Palabras clave"
                  hint="Palabras con las cuales los compradores podrán encontrar tu tienda. Presiona ENTER para agregar una nueva."
                  persistent-hint
                  filled
                  multiple
                  small-chips
                ></v-combobox>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="mt-3 text-right">
          <v-btn class="rounded-lg" color="primary" @click="save">
            Guardar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Edit } from "@/graphql/mutations/store";
import { BusinessCategories } from "@/utils/business_category";

export default {
  data() {
    return {
      valid: true,
      modal: false,
      load: false,
      profileTypes: [
        {
          id: "1",
          title: "stores.admin.profileType1",
          src: "profileType1.svg",
        },
        {
          id: "2",
          title: "stores.admin.profileType2",
          src: "profileType2.svg",
        },
      ],
      store: {},
      categories: BusinessCategories,
      alert: {
        active: false,
        type: "error",
        message: "",
      },
      rules: {
        required: [(v) => !!v || "Campo obligatorio"],
      },
    };
  },

  watch: {
    modal() {
      if (!this.load) {
        this.modal = false;
        this.load = true;
      }
    },
  },

  computed: {
    slug() {
      return window.location.host + "/p/" + this.user.store.slug;
    },

    marker() {
      return (
        this.store.informationAttributes.latitude &&
        this.store.informationAttributes.longitude
      );
    },
  },

  mounted() {
    this.store = {
      ...this.store,
      ...JSON.parse(JSON.stringify(this.user.store)),
    };
    delete this.store.__typename;
    delete this.store.favoritesCount;
    delete this.store.aboutUs;
    delete this.store.header;
    delete this.store.sections;
    delete this.store.paymentMethods;
    delete this.store.products;
    delete this.store.transbankCodeApplied;
    delete this.store.information.__typename;
    delete this.store.preferences.__typename;
    delete this.store.address.__typename;
    delete this.store.id;
    delete Object.assign(this.store, {
      ["addressAttributes"]: this.store["address"],
    })["address"];
    delete Object.assign(this.store, {
      ["informationAttributes"]: this.store["information"],
    })["information"];
    delete Object.assign(this.store, {
      ["preferencesAttributes"]: this.store["preferences"],
    })["preferences"];
    if (this.store.informationAttributes.address) {
      delete Object.assign(this.store.informationAttributes, {
        ["addressAttributes"]: this.store.informationAttributes["address"],
      })["address"];
      delete this.store.informationAttributes.addressAttributes.__typename;
    }
    delete this.store.informationAttributes.address;
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (
          this.store.informationAttributes.addressAttributes &&
          !this.store.informationAttributes.addressAttributes.number
        ) {
          this.alert.message = "Altura de calle obligatoria";
          this.alert.type = "error";
          this.alert.active = true;
        } else if (
          this.store.addressAttributes &&
          !this.store.addressAttributes.number
        ) {
          this.alert.message = "Altura de calle obligatoria";
          this.alert.type = "error";
          this.alert.active = true;
        } else {
          this.alert.active = false;
          this.$apollo
            .mutate({
              mutation: Edit,
              variables: {
                input: {
                  id: this.user.store.id,
                  attributes: this.store,
                },
              },
            })
            .then((res) => {
              this.alert.message = this.$t("object.edit.success");
              this.alert.type = "success";
              this.alert.active = true;
              setTimeout(() => {
                this.alert.active = false;
              }, 2000);
            })
            .catch((err) => {
              this.alert.message = this.$t(
                "validation." + err.graphQLErrors[0].extensions.message,
                { field: err.graphQLErrors[0].extensions.field }
              );
              this.alert.type = "error";
              this.alert.active = true;
            });
        }
      }
    },

    setPlace(place) {
      if (!place.street_number) {
        this.alert.message = "Altura de calle obligatoria";
        this.alert.type = "error";
        this.alert.active = true;
        this.$nextTick(() => {
          this.store.informationAttributes.fullAddress = null;
        });
      } else {
        this.alert.active = false;
        this.store.informationAttributes.latitude = place.latitude;
        this.store.informationAttributes.longitude = place.longitude;
        this.store.informationAttributes.addressAttributes = {
          street: place.route,
          number: place.street_number,
          fullAddress: `${place.route} ${place.street_number}, ${place.locality}`,
          unit: null,
          neighbourhood: place.locality,
          city: place.administrative_area_level_2,
          state: place.administrative_area_level_1,
          country: place.country,
        };
      }
    },

    setAddressPlace(place) {
      if (!place.street_number) {
        this.alert.message = "Altura de calle obligatoria";
        this.alert.type = "error";
        this.alert.active = true;
        this.$nextTick(() => {
          this.store.addressInformation.fullAddress = null;
        });
      } else {
        this.alert.active = false;
        this.store.addressAttributes = {
          ...this.store.addressAttributes,
          ...{
            fullAddress: `${place.route} ${place.street_number}, ${place.locality}`,
            street: place.route,
            number: place.street_number,
            unit: null,
            neighbourhood: place.locality,
            city: place.administrative_area_level_2,
            state: place.administrative_area_level_1,
            country: place.country,
          },
        };
      }
    },
  },

  components: { VueGoogleAutocomplete },
};
</script>

<style scoped>
>>> [class^="col"] {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
